@import url('https://fonts.googleapis.com/css2?family=Abril+Fatface&family=Anton&family=BIZ+UDPMincho&family=Bebas+Neue&family=Montserrat:wght@800&family=Noto+Sans+JP:wght@500&family=Noto+Sans+KR:wght@500;700;900&family=Noto+Sans+SC&family=Noto+Serif+JP:wght@500&family=Roboto&family=Shippori+Mincho+B1:wght@700&display=swap');

@font-face {
  font-family:"Pretendard";
  src: url(/public/assets/Pretendard-SemiBold.ttf);
  font-weight: bold;
};

@font-face {
  font-family:"PretendardJP";
  src: url(/public/assets/public/static/PretendardJP-SemiBold.otf);
  font-weight: bold;
};
@font-face {
  font-family:'YuMincho';
  src: url(/public/assets/yumin.ttf);
}
@font-face {
  font-family:'MsyhCN';
  src: url(/public/assets/chinese.msyh.ttf);
}
html{
	scroll-behavior: smooth;
  font-family: 'Pretendard', sans-serif !important;
}

._en {
  font-family: 'Pretendard', sans-serif !important;
}

._jp {
  font-family: 'Shippori Mincho B1', serif;
  /* font-weight: bold; */
}

._cn {
  font-family: 'MsyhCN', sans-serif !important;
}

._ru {
  font-family: 'Pretendard', sans-serif !important;
}

._vn {
  font-family: 'Pretendard', sans-serif !important;
}

.text_yellow {
  color:yellow
}

.header {
  width: 100vw;
  height: 100px;
  display: flex;
  align-items: center;
  justify-content: space-around;
  position: fixed;
  top:0px;
  left: 0;
  z-index:99;
  color: #fff;
  font-weight: bold;
  font-size:30px;
  padding: 20px 0;
  /* background: rgb(121 121 121 / 40%); */
  background-color: transparent;
  background-image: linear-gradient(180deg, #212423, rgba(35, 35, 35, 0));
  transition: opacity 800ms ease;
}

.header_logo {

}

.header_menu {
  display: flex;
  width: 60%;
  font-size: 22px !important;
  align-items: center;
  justify-content: center;
}

.header_menu > p {
  width: 20%;
  text-align: center;
}

.header_select_lang select {
  color: white;
    border: none;
    padding: 10px;
    border-radius: 10px;
    background: transparent;
    font-size: 18px;
}
.header_select_lang select option {
  background: rgb(255 255 255);
  opacity: 0;
  color: black;
}

.footer {
  background-color: #000;
}

.footer p {
  color: #fff;
  margin: 0;
  padding: 20px 20px 20px 0;
  text-align: right;
}
/* main */

.main_section1 {
  position: relative;
  height: 100vh;
  background-color: #000;
}

.video_container {
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.video_container video {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.main_section1_text_box {
  position: absolute;
    color: #fff;
    /* top: calc( 100% - 31% ); */
    /* left: calc( 100% - 49% ); */
    background: rgb(0 0 0 / 70%);
    width: 55vw;
    padding:40px 20px;
    bottom: 0;
    right: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    /* height: 400vh; */
}

.main_section1_text_box h1 {
  font-size:39px;
  margin: 10px 0;
}

.main_section1_text_box p {
  font-size: 1.4rem;
  /* word-break: break-all; */
  line-height: 34px;
}

/* main section2 */

.main_service_introduce_section {
  height: 100vh;
  position: relative;
}

.main_service_introduce_center {
  position: absolute;
  top: 53%;
  left: 50%;
  transform: translate(-50%,-50%);
}

.main_service_introduce_center img {
  width: 300px;
}

.main_section3 {
  background-color: #000;
  width: 100%;
  height: 100vh;
  display: flex;
  /* background-image: url('/public/assets/main_section3_bg.jpg');
  background-repeat: no-repeat;
  background-position: right; */
}

.main_section3_left_container {
  width: 40%;
  color: #fff;
  position: relative;
}

.main_section3_left_container > div {
  display: flex;
  justify-content: center;
  flex-direction: column;
  height:100%;
  position: absolute;
  text-align:center;
  left: 25%;
}

.main_section3_left_container > div h1 {
  font-size: 60px;
  /* margin-bottom: 130px; */
  /* font-family: 'Anton', sans-serif; */
  line-height: 1.4em;
}

.main_section3_left_container > div p {
  font-size:29px;
  opacity:0.8;
  text-align: center;
  line-height: 40px;
  /* font-family: 'Anton', sans-serif; */
}

.main_section3_right_container {
  width: 60%;
  padding-right: 1%;
  position: relative;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
}
.main_section3_right_container::after {
  background-image: url('/public/assets/main_section3_bg.png');
  background-repeat: no-repeat;
  background-position: center;
  background-size:85% 85%;
  width: 100%;
  height: 100%;
  content: "";
  position: absolute;
  transform: translate(-50%,-50%);
  top: 50%;
  left: 50%;
  z-index: -1;
  opacity: 0.4
}
.main_section3_right_container img {
  width: 85%;
  height: 85%;
  display: block;
  margin-left: auto;
  margin-top: 55px;
}
/* company */
.main_company_section1_wrapper {
  background: #000;
  color: #fff;
  height: 110vh;
  width: 100%;
  /* font-family: 'Noto Sans KR', sans-serif; */
  font-weight: 500;
  display: flex;
  justify-content: center;
  flex-direction: column;
}
.main_company_section1_text_box {
  text-align: center;
  padding: 50px 0 30px;
}

.main_company_section1_text_box h1 {
  font-size: 60px;
  margin-top: 20px;
  /* font-family: 'Abril Fatface', cursive; */
}

.main_company_section1_text_box p {
  font-size: 20px;
  margin: 25px auto;
  line-height: 43px;
  width: 95vw;
}

.main_company_section1_content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 24vw;
}

.main_company_section1_content._jp p {
  /* word-break: break-all; */
}

.main_company_section1_container {
  display: flex;
  align-items: center;
  justify-content: space-around;
  text-align: center;
}

.main_company_section1_feature {
  position: relative;
  width: fit-content;
}

.main_company_section1_feature img {
  width: 250px;
  height: 250px;
}

.main_company_section1_feature h4 {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 22px;
  line-height: 35px;
  font-weight: 500;
  word-break: keep-all;
  /* word-break: break-all; */
}

.main_company_section1_container p {
  margin-top: 25px;
  line-height: 2rem;
  font-size: 1rem;
  width: 100%;
  height: 160px;
}

/* company section2 */

.main_company_section2_wrapper {
  color: #fff;
  width: 100%;
  height: 100vh;
  background: url("/public/assets/main_company_section1_bg1.jpg") no-repeat;
  background-size: cover;
  display: flex;
  align-items: center;
  /* font-family: 'Noto Sans KR', sans-serif; */
  font-weight: 500;
}

.main_company_section2_text_box {
  font-size: 40px;
  width: 60%;
  margin-left: 3%;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  height: 50vh;
}

.main_company_section2_text_box h1 {
  margin: 20px 0;
  font-weight: 400;
  line-height: 55px;
}

/* company section3 */

.main_company_section3_wrapper {
  color: #fff;
  width: 100%;
  height: 100vh;
  background: url("/public/assets/main_company_section1_bg2.jpg") no-repeat;
  background-size: cover;
  display: flex;
  align-items: center;
  justify-content: center;
  /* font-family: 'Noto Sans KR', sans-serif; */
  font-weight: 500;
}

.main_company_section3_text_box h1 {
  margin: 20px 0;
  font-weight: 400;
  text-align: center;
  font-size: 40px;
  line-height: 60px;
  width: 95vw;
}

/* company section4 */

.main_company_section4_wrapper {
  color: #fff;
  width: 100%;
  height: 100vh;
  background: url("/public/assets/main_company_section1_bg3.jpg") no-repeat;
  background-size: cover;
  position: relative;
  /* font-family: 'Noto Sans KR', sans-serif; */
  font-weight: 500;
}

.main_company_section4_text_box {
  position: absolute;
  top: 10%;
  left: 3%;
  width: 50vw;
}

.main_company_section4_text_box h1 {
  font-weight: 400;
  font-size: 40px;
  line-height: 80px;
}

/* onebot algorithm */

.onebot_algorithm_section1_wrapper {
  width: 100%;
  height: 100vh;
  background: url("/public/assets/onebot_algorithm_bg1.png") no-repeat;
  background-size: cover;
  text-align: center;
  position: relative;
  display: flex;
  align-items: center;
  /* font-family: 'Anton', sans-serif; */
}

.onebot_algorithm_section1_left {
  width: 50%;
}
.onebot_algorithm_section1_right {
  width: 50%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-right: 1%;
}

.onebot_algorithm_section1_right p {
  color: #fff;
  font-size: 20px;
  line-height: 1.7rem;
  margin: 40px auto;
}

.onebot_algorithm_section1_right p:nth-child(1) {
  /* width: 300px; */
}

.onebot_algorithm_section1_right p:nth-child(2) {
  /* width: 320px; */
}

.onebot_algorithm_section1_right img {
  width: 100%;
  height: 100%;
}
/* .onebot_algorithm_section1_text_left , .onebot_algorithm_section1_text_right_top , .onebot_algorithm_section1_text_right_bottom, .onebot_algorithm_section1_text_center {
  position: absolute;
} */

/* .onebot_algorithm_section1_text_center {
  top: 56%;
  left: 50%;
  transform: translate(-50%,-50%);
}

.onebot_algorithm_section1_text_left {
  left:5%;
  top: 50%;
  transform: translateY(-50%);
}

.onebot_algorithm_section1_text_right_top {
  top: 10%;
  right:15%;
}

.onebot_algorithm_section1_text_right_bottom {
  bottom:15%;
  right:20%;
}
 */
.onebot_algorithm_section1_wrapper h1 {
  color:#fff;
  font-size: 70px;
  line-height:1.4em;
  font-weight: 100;
}

.onebot_algorithm_section1_wrapper h2 {
  font-size:25px;
  line-height:40px;
  letter-spacing: 2px;
  font-weight: 500;
  /* width: 300px; */
}

.onebot_algorithm_section2_wrapper {
  width: 100%;
  height: 100vh;
  padding:50px 0;
  background: #000;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  /* font-family: 'Montserrat', sans-serif; */
}

.onebot_algorithm_section2_container {
  width: 90%;
  margin: auto;
}

.onebot_algorithm_section2_container h1 {
  margin: 40px 0;
  font-size: 30px;
}

.onebot_algorithm_section2_text_box {

}

.onebot_algorithm_section2_flex_content {
  display: flex;
  justify-content: space-between;
  align-items: self-start;
}

.onebot_algorithm_section2_content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  width: 33%;
}

.onebot_algorithm_section2_content img {
  width: 100%;
}

.onebot_algorithm_section2_content > div {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background-color: #fff;
  color: #000;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 20px 0;
  font-weight: bold;
}

.onebot_algorithm_section2_content > div p {
  margin: 0;
}

.onebot_algorithm_section2_content h2 {
  font-size:26px;
  color: mediumturquoise;
  text-align: center;
  height: 50px;
}

.onebot_algorithm_section2_content p {
  text-align: center;
  line-height:30px;
  margin-top: 30px;
  width: 90%;
}
/* company names */
.main_company_names_wrapper {
  width: 100%;
  height: 100vh;
  image-rendering:-webkit-optimize-contrast !important;
    transform:translateZ(0);
    backface-visibility:hidden;
  /* background-repeat: no-repeat;
  background-position: center;
  background-size: 100% 100%; */
}
.main_company_names_wrapper img {
  width: 100%;
  height: 100%;
}
/* contact */

.main_contact_section {
  width: 100%;
  height: 100vh;
  background: url("/public/assets/main_contact_bg.jpg") no-repeat;
  background-size: cover;
  color: #fff;
  /* font-family: 'Abril Fatface', cursive; */
  font-weight: 500;
}

.main_contact_input_box {
  width: 50%;
  background: rgb(0 0 0 / 65%);
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.main_contact_input_container {
  width: 90%;
  margin: auto;
}

.main_contact_input_box h1 {
  font-size:55px;
  letter-spacing: 1.2px;
  margin: 30px 0;
}

.main_contact_line_with_circle {
  position: relative;
  width: calc(100% - 30px);
  height: 2px;
  background-color: #fff;
}

.main_contact_circle {
  position: absolute;
  top: -13px;
  right: -30px;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  border:1px solid #fff;
}

.main_contact_input_info {
  display: flex;
  align-items: center;
  margin:10px 0;
}

.main_contact_input_info p {
  width: 300px;
  font-size: 23px;
}

.main_contact_input_info input {
  background: none;
  border: 1px solid #fff;
  height: 42px;
  width: 100%;
    height: 42px;
    color: #fff;
    font-size: 25px;
}

.main_contact_input_content {
  font-size: 23px;
}

.main_contact_input_content_text_area {
  width: 100%;
  border: 1px solid #fff;
  height: 300px;
  margin: 17px 0 15px;
  font-size: 17px;
}

.main_contact_btn {
  color: #fff;
  background: none;
  border: 1px solid #fff;
  font-weight: bold;
  width: 150px;
  height: 42px;
  display: block;
  margin: auto;
  font-size:18px;
  /* font-family: 'Abril Fatface', cursive; */
}

/* servie_introduce */

.main_service_introduce_container {
  display: flex;
  height: 50vh;
}

.main_service_introduce_container_bg_w {
  background:#fff;
  width: 50%;
  height: 100%;
}

.main_service_introduce_container_bg_b {
  background:#000;
  width: 50%;
  height: 100%;
}

.main_service_introduce_content {
  margin: 50px;
  /* border-bottom:2px solid orange; */
  height: calc(100% - 50px );
  display: flex;
  flex-direction:column;
  justify-content: center;
}

.main_service_introduce_content h1 {
  font-size:30px;
  margin: 20px 0 20px;
  /* font-family: 'Abril Fatface', cursive; */
  font-style: italic;
}

.main_service_introduce_content p {
  font-size:20px;
  font-weight: bold;
  margin: 0;
  /* font-family: 'Montserrat', sans-serif; */
}

.main_service_introduce_content img {
  width: 150px;
}

.main_service_introduce_content .main_service_introduce_content_img_sm {
  width: 130px;
}

.main_service_introduce_container_bg_w .main_service_introduce_content {
  align-items: flex-start;
}

.main_service_introduce_container_bg_b .main_service_introduce_content {
  align-items: flex-end;
  color: #fff;
  text-align: right;
}

.main_service_introduce_content:nth-child(3),
.main_service_introduce_content:nth-child(4) {
  border-bottom: none;
}
.main_service_introduce_container_bg_all {
  width: 100%;
  background-color: #fff;
  border-bottom:3px solid orange;
}
.main_service_introduce_container_bg_all .main_service_introduce_content {
  align-items: center;
  margin: 0;
  height: calc(100% - 123px );
}

.main_service_introduce_container_bg_all .main_service_introduce_content img {
  width: 130px;
}

.main_service_introduce_container_bg_all .main_service_introduce_content h1 {
  font-size: 27px;
}

/* roadmap */
.main_roadmap_section1_wrapper {
  width: 100%;
  height: 100vh;
  color: #fff;
  font-weight: bold;
  background-color: #000;
  text-align: center;
  padding: 10px 0;
}

.main_roadmap_section1_wrapper h1 {
  padding: 15px;
  text-align: left;
  font-size: 45px;
  background: #080321;
  padding-left: 5%;
  /* font-family: 'Bebas Neue', sans-serif; */
}

.main_roadmap_section1_container {
  display: flex;
  align-items: center;
  justify-content: space-around;
  height: 75vh;
  background: linear-gradient(to right, #00002b,#1d0065 );
  background-size: 100% 3%;
  background-repeat: no-repeat;
  background-position: center;
  width: 90vw;
  margin:10px auto;
}
.main_roadmap_section1_content {
  margin-top: -20%;
  width: 20vw;
}
.main_roadmap_section1_content > div { display: flex; align-items: center; justify-content: center;}
.main_roadmap_section1_content > div .circle {
  width: 30px;
    height: 30px;
    background: hsl(0, 1%, 25%);
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 5px;
    font-size:1rem;
    min-height: 30px;
    min-width: 30px;
}
.main_roadmap_section1_content:nth-child(2n-1) {
  margin-top: 22%;
}

.main_roadmap_section1_content img{
  width: 100%;
}

.main_roadmap_section1_content p {
  font-size:1.3rem;
}
.main_roadmap_section1_content:nth-child(4) img {
  width: 78%;
}

.main_roadmap_section1_content:nth-child(4) {
  margin-top: -24%;
}

.main_roadmap_section1_content:nth-child(4) p {
  margin:0;
  margin-top: 0.5em;
}

/* .main_roadmap_section1_content:nth-child(5) p {
  font-size: 1.8rem;
} */

.grid-container {
  display: grid;
  grid-template-rows:1fr;
  grid-template-columns: 1fr 1fr;
  grid-gap: 1rem;
}

.grid-item {
  background-color: #ccc;
  padding: 10px;
  text-align: center;
}

.grid-item:nth-child(even) {
  position: relative;
    top: 2rem;
}

.main_service_introduce_section._jp .main_service_introduce_container_bg_all .main_service_introduce_content h1 {
  font-size: 23px;
}
.main_service_introduce_section._jp .main_service_introduce_content p {
  font-size: 16px;
}
@media screen and (min-width: 2000px){
  .main_service_introduce_container_bg_all .main_service_introduce_content img {
    width: 170px;
  }
  .main_service_introduce_center img{
    width: 500px;
  }
  .main_section1_text_box h1 {
    font-size: 50px;
  }
  .main_section1_text_box p {
    font-size: 2rem;
  }
  .header {
    font-size:45px;
  }
  .header_menu {
    font-size:30px !important
  }
  .header_select_lang select { font-size:30px}
  .main_company_section1_text_box h1 { font-size: 80px; margin: 50px 0;}
  .main_company_section1_text_box p {font-size: 32px;}
  .main_company_section1_container {margin-top: 50px;}
  .main_company_section1_feature { width: 100%;}
  .main_company_section1_feature img { width: 400px; height: 400px;}
  .main_company_section1_feature h4 { font-size: 30px;}
  .main_company_section1_container p { font-size: 28px; margin-top: 50px;}
  .main_company_section2_text_box { font-size: 60px;}
  .main_company_section3_text_box h1 { font-size: 55px;}
  .main_company_section4_text_box h1 { font-size: 55px;}
  .onebot_algorithm_section1_wrapper h2 {font-size: 35px;}
  .onebot_algorithm_section2_container h1 { font-size: 50px;}
  .onebot_algorithm_section2_content h2 { font-size: 35px;}
  .onebot_algorithm_section2_content p { font-size: 30px;}
  .main_service_introduce_content img { width: 200px;}
  .main_service_introduce_content h1 { font-size: 40px;}
  .main_section3_left_container > div h1 { font-size: 70px;}
  .main_section3_left_container > div p { font-size: 40px;}
  .main_section3_left_container > div p { font-size: 35px;}
  .main_contact_input_box h1 { font-size: 70px;}
  .main_contact_input_info p { font-size: 40px;}
  .main_contact_input_info input { height: 80px; font-size: 35px;}
  .main_contact_input_content {font-size: 40px;}
  .main_contact_input_content_text_area { height: 400px; margin: 30px 0;}
  .main_contact_btn { width: 200px; height: 60px;}
  .main_roadmap_section1_content p { font-size: 2rem;}
  .main_roadmap_section1_wrapper h1 { font-size: 60px;}
  .main_service_cr_upper_title { font-size: 4rem !important;}
  .main_service_cr_upper_sub { font-size: 2.3rem !important;}
  .main_service_cr_lower_title_left { font-size: 2.5rem !important;}
  .main_service_cr_lower_desc_left { font-size: 1.7rem !important;}
  .main_service_cr_lower_left { height: 500px !important;}
  .main_service_cr_lower_right { height: 500px !important;}
  .main_service_cr_lower_title_right { font-size: 2.5rem !important;}
  .main_service_cr_lower_desc_right {  font-size: 1.7rem !important;}
  .main_roadmap_section1_content > div .circle {
    font-size: 1.8rem;
    width: 50px;
    height: 50px;
  }
  .main_roadmap_section1_content:nth-child(4) p {
    margin: 0;
    margin-top: 9.5%;
    margin-right: 5px;
  }
  .main_service_dr._jp .main_service_dr_text_header {
    font-size: 3.4rem;
  }
  .onebot_algorithm_section1_right_top p{
    font-size: 30px;
  }
  .height_high {
    height: 100% !important;
  }
}


@media screen and (max-width: 1440px) {
  .main_service_dr._jp .main_service_dr_text_header {
    font-size: 2rem;
  }
  .main_section3._jp .main_section3_left_container > div { left: 2%;}
  .main_section3._jp .main_section3_left_container > div h1 { font-size: 45px;}
  .main_section3._jp .main_section3_left_container > div p { font-size: 20px;}
  .main_roadmap_section1_content img {
    height: auto;
  }
  /* .main_roadmap_section1_content:nth-of-type(4) img {
    height: 270px;
  }
  .main_roadmap_section1_content:nth-of-type(4) p {
    left: 18%;
  }
  .onebot_algorithm_section2_content h2 {
    height: 40px;
  } */
  .main_service_introduce_content {
    margin: 35px;
    height: calc(100% - 35px );
  }
  .main_service_introduce_content p {
    font-size: 19px;
  }
  .main_roadmap_section1_content p {
    font-size:19px;
  }
  .onebot_algorithm_section2_wrapper {
    height:100vh;
  }
  ._jp.main_service_dl .main_service_dl_lower_left {
    font-size:2.8rem !important;
  }
  .main_company_section1_container p {
    font-size: 0.8rem;
  }
  .main_company_section2_text_box { font-size: 35px;}
  .main_company_section3_text_box h1{ font-size: 35px;}
  .main_company_section4_text_box h1{ font-size: 35px;}
  .onebot_algorithm_section1_wrapper h2 { font-size: 18px;}
  .onebot_algorithm_section2_content h2 { font-size: 22px;}
  .onebot_algorithm_section2_content p { font-size: 13px;}
  .main_roadmap_section1_content p {
    font-size: 16px;
  }
}

@media screen and (max-width: 1024px) {
  .main_company_section1_container { flex-wrap: wrap;}
  .main_service_dr._jp .main_service_dr_text_header {
    font-size: 1.2rem;
  }
  .main_service_dr._jp .main_service_dr_text_sub {
    font-size: 1.1rem;
  }
  .main_service_dr._jp .main_service_dr_desc_item {
    font-size: 1.1rem;
  }
  .header_menu {
    font-size: 18px !important;
    justify-content: space-between;
  }
  /* main */
  .main_section1_text_box {
    width: 70vw;
  }
  .main_section1_text_box h1 {
    font-size: 35px;
  }
  .main_section1_text_box p {
    font-size: 18px;
  }
  /* company */
  .main_company_section1_wrapper {
    height: fit-content;
  }
  .main_company_section1_text_box h1 {
    font-size: 50px;
  }
  .main_company_section1_text_box p {
    font-size: 18px;
  }
  .main_company_section1_feature h4 {
    font-size:20px;
  }
  .main_company_section1_feature img {
    width: 22vw;
    height: auto;
  }
  .main_company_section1_wrapper._ru .main_company_section1_container p {
    height: 210px;
  }
  .main_company_section1_content { width: 48vw;}
  .main_company_section1_container p {
    font-size:15px;
  }
  .main_company_section2_wrapper {
    background-position:center;
  }
  .main_company_section2_text_box {
    font-size:27px;
  }
  .main_company_section3_wrapper {
    background-position:center;
  }
  .main_company_section3_text_box h1 { font-size: 30px;}
  .main_company_section4_wrapper {
    background-position:center;
  }
  .main_company_section4_text_box {
    width: 50vw;
    word-wrap: break-word;
  }
  .main_company_section4_text_box h1 {
    font-size:25px;
  }
  /* algorithm */
  .onebot_algorithm_section1_wrapper {
    background-position: center;
  }
  .onebot_algorithm_section1_left h1{
    font-size: 50px;
  }
  .onebot_algorithm_section1_text_center {
    top: 52%;
  }
  .onebot_algorithm_section1_wrapper h2 {
    font-size: 15px;
    word-break: break-word;
  }
  .onebot_algorithm_section1_text_right_top {
    right: 0%;
  }
  .onebot_algorithm_section1_text_left {
    /* width: 240px; */
    top: 60%;
    left: 3%;
  }
  .onebot_algorithm_section1_text_right_bottom {
    /* width: 240px; */
    right: 5%;
  }
  .onebot_algorithm_section2_content img {
    width:25vw;
  }
  .onebot_algorithm_section2_content h2 {
    height: 45px;
    font-size: 18px;
  }
  .onebot_algorithm_section2_content p {
    width: 95%;
    margin-top: 10px;
  }
  /* service */
  .main_section3_left_container > div h1 {
    margin-bottom: 20px;
    font-size: 50px;
  }
  .main_service_introduce_center img {
    width: 300px;
  }
  .main_service_introduce_content img {
    width: 145px;
  }
  .main_service_introduce_content h1 {
    font-size: 25px;
  }
  .main_service_introduce_content p {
    font-size: 15px;
  }
  .main_section3_left_container > div p {
    font-size: 22px;
  }
  .main_roadmap_section1_content:nth-child(4) {
    margin-top: -29%;
  }
  .main_roadmap_section1_content:nth-of-type(4) img {
    height: auto;
    width: 86%;
  }
  .main_roadmap_section1_content:nth-of-type(4) p {
    left: 15%;
  }
  .main_roadmap_section1_content:nth-of-type(5) p {
    font-size: 22px;
  }
  .main_roadmap_section1_content p {
    font-size:16px;
  }
  .main_roadmap_section1_content {
    margin-top: -15rem;
  }
  .main_roadmap_section1_content:nth-child(2n-1) {
    margin-top: 14rem;
  }
  .main_contact_section {
    background-position: center;
    background-size: 100% 100%;
  }
  .onebot_algorithm_section2_content p {
    font-size: 14px;
  }
  .main_section3_right_container img {
    height: 65%;
    width: 100%;
  }
  .main_section3_left_container > div { left: 3%;}
  .main_roadmap_section1_container_mb {
    background: linear-gradient(to right, #00004f,#1c0061 );
    background-size: 8% 100%;
    background-repeat: no-repeat;
    background-position: center;
    flex-direction: column;
    height: auto;
    padding: 30px 0;
    margin: 0 auto;
  }
  .main_roadmap_section1_content_mb {
    width: 50vw;
  }
  .main_roadmap_section1_content_mb img {
    width: 100%;
  }
  .main_roadmap_section1_content_mb p{
    /* background-color: #000; */
    font-size: 21px;
    padding: 12px 0;
    margin: 0;
  }
  .main_roadmap_section1_wrapper {
    padding: 20px 0 0;
    height:fit-content;
  }
  ._jp.main_service_dl .main_service_dl_lower_left {
    /* font-size:3rem !important; */
  }
  .main_section3._jp .main_section3_left_container > div h1 {
    font-size: 31px;
  }
  ._jp.main_service_dl .main_service_dl_lower_left {
    font-size: 2.2rem !important;
  }
  /* company names */
  .main_company_names_wrapper {
    /* background-size: cover; */
  }
}


@media screen and (max-width: 768px ) {
  /* company names */
  .main_company_names_wrapper {
    height: 1300px;
  }
  .main_service_dr._jp .main_service_dr_text_header {
    font-size: 1.7rem
  }
  .header_menu {
    font-size: 16px !important;
  }
  .header_logo {
    font-size:23px;
  }
  /* company */
  .main_company_section1_text_box h1 {
    font-size: 50px;
  }
  .main_company_section1_text_box p {
    font-size: 15px;
    padding:0 10px;
  }
  .main_company_section1_feature h4 {
    font-size:18px;
  }
  .main_company_section1_container p {
    font-size: 14px;
    height: 200px;
    /* word-break: keep-all; */
    margin: 20px -4px;
  }
  .main_company_section1_content {
    width:45vw;
  }
  .main_company_section1_feature {
    width: 100%;
  }

  .main_company_section1_feature img {
    width:70%;
  }
  .main_company_section3_text_box  h1{
    font-size:25px;
  }
  /* algorithm */
  .onebot_algorithm_section1_wrapper {
    flex-direction: column;
    height: auto;
  }
  .onebot_algorithm_section1_left {
    width: 100%;
  }
  .onebot_algorithm_section1_right {
    width: 100%;
    margin: 0;
  }
  .onebot_algorithm_section1_right img {
    width: 99%;
    height: auto;
  }
  .onebot_algorithm_section1_wrapper h2 {
    font-size: 18px;
    /* width: 185px; */
    word-wrap: break-word;
  }

  .onebot_algorithm_section1_wrapper h1 {
    font-size:50px;
  }

  .onebot_algorithm_section1_right p:nth-child(1) {
    width: 90%;
  }

  .onebot_algorithm_section1_right p:nth-child(2) {
    width: 90%;
  }

  .onebot_algorithm_section2_content h2 {
    font-size: 15px;
  }
  .onebot_algorithm_section2_content p {
    font-size: 11px;
  }
  .onebot_algorithm_section2_content > div {
    width: 40px;
    height: 40px;
  }
  .main_service_introduce_center img {
    width: 280px;
  }
  .main_service_introduce_container_bg_all .main_service_introduce_content p {
    text-align: center;
  }
  .main_service_introduce_content {
    margin: 10px;
    height: 100%;
  }
  .main_service_introduce_content img {
    width: 130px;
  }
  .main_service_introduce_content h1 {
    font-size: 24px;
  }
  .main_section3 {
    align-items: center;
  }
  .main_section3_right_container {
    width: 60%;
  }
  .main_section3_left_container > div {
    left: 5%;
  }
  .main_section3_left_container > div h1 {
    margin-bottom: 20px;
    text-align: center;
    font-size: 25px;
  }
  .main_section3_left_container > div p {
    font-size: 18px;
  }
  .main_contact_input_box {
    width: 100%;
  }
  .main_service_introduce_content p {
    width: 99%;
  }
  .main_service_introduce_content p {
    font-size: 13px;
  }
  .main_company_section4_text_box h1 {
    font-size: 21px;
    word-break: keep-all;
  }
  .main_section3._jp .main_section3_left_container > div p {
    font-size: 14px;
  }
  .main_section1_text_box {
    width: 100vw;
    padding: 15px;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
    background: none;
    text-align: center;
    background: rgb(0 0 0 / 11%);
  }
  .main_section1_text_box h1 {
    font-size: 35px;
    margin: 30px 0;
  }
  .main_section1_text_box p {
    font-size: 18px;
    margin: 30px 0;
    line-height: 28px;
  }
  .video_container video {
    object-fit: fill;
  }
  .main_company_section2_text_box {
    font-size: 21px;
  }
  .onebot_algorithm_section1_wrapper h2 {
    font-size: 15px;
  }
  .onebot_algorithm_section1_text_left {
    left:5px;
  }
  .onebot_algorithm_section2_container { width: 96%;}
  .onebot_algorithm_section2_content { width: 34%;}
  .main_section3._jp .main_section3_left_container > div h1 {
    font-size: 25px;
  }
  .header_menu > p { font-size: 14px;}
  .main_roadmap_section1_content_mb p { font-size: 18px;}
}

@media screen and (max-width:576px ) {
  .main_company_names_wrapper {
    height: 800px;
  }
  .main_service_dr._jp .main_service_dr_text_header {
    font-size: 1.5rem;
  }
  /* header */
  .header {
    justify-content:space-between;
  }
  .header_logo {
    font-size: 15px;
    width: 50px;
  }
  .header_menu {
    width: 65%;
    font-size: 12px !important;
    justify-content: space-between;
  }
  .header._ru .header_menu {
    justify-content: flex-start;
    width: 68%;
    font-size: 11px !important;
  }
  .header._ru .header_select_lang select {margin-left: -8px; font-size: 11px;}
  .header_menu > p { width: auto; margin-right: 3px; font-size: inherit;}
  .header_select_lang select {
    padding: 10px 0;
    font-size: 12px;
    margin-left: 6px;
  }
  .header_menu > p:nth-of-type(4) {
    text-align: center;
  }
  .main_company_section1_text_box h1 {
    font-size: 40px;
  }
  /* main */
  .main_section1_text_box {
    width: 100vw;
    padding: 15px;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
    background: none;
    text-align: center;
    background: rgb(0 0 0 / 11%);
  }
  .main_section1_text_box h1 {
    font-size: 26px;
    margin: 30px 0;
  }
  .main_section1_text_box p {
    font-size: 14px;
    margin: 30px 0;
    line-height: 28px;
    word-break: keep-all;
  }
  .video_container video {
    object-fit: fill;
  }
  /* company */
  .main_company_section1_wrapper {
    height: fit-content;
    padding: 20px 0;
  }
  .main_company_section1_container {
    flex-wrap: wrap;
  }
  .main_company_section1_content {
    width: 95vw;
  }
  .main_company_section1_container p {
    height: fit-content;
    font-size: 11px;
  }
  .main_company_section2_wrapper {
    height: 50vh;
  }
  .main_company_section2_text_box {
    font-size: 32px;
    margin: auto;
    text-align: center;
    justify-content: center;
    width: 100%;
  }
  .main_company_section2_text_box h1 {
    /* word-break: keep-all; */
    font-size: 18px;
    margin: 10px 0;
    line-height: 30px;
  }
  .main_company_section3_wrapper {
    height: 50vh;
  }
  .main_company_section3_text_box {
    margin: auto;
    text-align: center;
  }
  .main_company_section3_text_box h1 {
    font-size: 17px;
    width: 100vw;
    /* word-break: keep-all; */
  }
  .main_company_section4_wrapper {
    background-position: bottom;
    height: 50vh;
  }
  .main_company_section4_text_box {
    position: absolute;
    bottom: 0%;
    left: 50%;
    text-align: center;
    top: inherit;
    transform: translateX(-50%);
    width: 100%;
    padding:0 10px;
  }
  .main_company_section4_text_box h1 {
    font-size: 17px;
    /* word-break: keep-all; */
    line-height: 35px;
  }
  /* algorithm */
  .onebot_algorithm_section1_wrapper {
    height: auto;
    padding: 20px 0;
  }
  .onebot_algorithm_section1_right {
    height: auto;
  }
  .onebot_algorithm_section1_right p {
    margin: 20px auto;
    font-size: 17px;
  }
  .onebot_algorithm_section1_wrapper h1 {
    font-size: 40px;
  }
  .onebot_algorithm_section1_left img {
    width: 150px;
  }
  .onebot_algorithm_section1_wrapper h2 {
    font-size: 13px;
  }
  .onebot_algorithm_section1_text_right_top {
    width: 100%;
    right: 0;
    top: 10%;
  }
  .onebot_algorithm_section1_text_right_top h2 {
    width: 100%;
  }
  .onebot_algorithm_section1_text_right_bottom {
    bottom: 5%;
    width: 100%;
    right: 0;
  }
  .onebot_algorithm_section1_text_left {
    left: 0%;
    top: 70%;
    width: 100%;
  }
  .onebot_algorithm_section2_wrapper {
    height: fit-content;
  }
  .onebot_algorithm_section2_flex_content {
    flex-direction: column;
    align-items: center;
  }
  .onebot_algorithm_section2_content {
    width: 100%;
  }
  .onebot_algorithm_section2_container h1 {
    text-align: center;
  }
  .onebot_algorithm_section2_content img {
    width: 70vw;
  }
  .onebot_algorithm_section2_content > div {
    margin: 20px 0;
  }
  .onebot_algorithm_section2_content p {
    margin:10px 0 20px;
  }
  .onebot_algorithm_section2_container h1 {
    font-size: 30px;
  }
  /* service */
  .main_service_introduce_center  img {
    display: none;
  }
  .main_service_introduce_container_bg_b .main_service_introduce_content {
    margin: 0;
    align-items: center;
  }
  .main_service_introduce_container_bg_all .main_service_introduce_content {
    height: 100%;
  }
  .main_service_introduce_container_bg_all .main_service_introduce_content h1 {
    font-size: 16px;
  }
  .main_service_introduce_content h1 {
    text-align: center;
    font-size: 15px;
  }
  .main_service_introduce_container_bg_w .main_service_introduce_content {
    align-items: center;
    margin: 0;
  }
  .main_service_introduce_content p {
    font-size: 14px;
    text-align: center;
    /* display: none; */
  }
  .main_service_introduce_content img {
    width: 120px;
    height: 120px;
  }
  .main_section3 {
    flex-direction: column;
    justify-content: center;
  }
  .main_section3_left_container > div {
    position: static;
  }
  .main_section3_right_container {
    width: 90vw;
  }
  .main_section3_left_container {
    width: 90vw;
  }
  .main_section3_left_container > div h1 {
    padding-top: 50px;
  }
  .main_roadmap_section1_content_mb p {
    font-size: 19px;
  }
  .main_roadmap_section1_wrapper {
    padding: 20px 0 5px;
  }
  .main_contact_input_box h1 {
    /* padding: 30px 0 0; */
    font-size: 30px;
  }
  .main_contact_input_content_text_area {
    margin:20px 0 34px;
  }
  .main_contact_section {
    height: fit-content;
  }
  .main_contact_btn {
    margin-bottom: 20px;
    font-size: 15px;
  }
  .main_section3_right_container img {
    margin: 0;
    height: 100%;
  }
  .main_section3._vn .main_section3_left_container > div p {
    font-size: 14px;
  }
  .main_section3_left_container > div p {
    line-height: 30px;
    font-size:15px;
  }
  .main_roadmap_section1_wrapper h1 { font-size: 30px;}
  ._jp.main_service_dl .main_service_dl_lower_left {
    font-size:1.8rem !important;
  }
  .main_section3._jp .main_section3_left_container > div h1 {
    font-size: 27px;
  }
  .main_section3._jp .main_section3_left_container > div p {
    font-size: 13px;
  }
  .main_company_section1_text_box p { font-size: 12px;}
  .main_company_section1_feature h4 {
    font-size: 16px;
  }
  .main_company_section1_feature img {
    width: 52%;
  }
  .main_roadmap_section1_content_mb {
    width: 70vw;
  }
}

@media screen and (min-height : 2000px) {
  .main_roadmap_section1_container_mb {
    background: linear-gradient(to right, #00004f,#1c0061 );
    background-size: 8% 100%;
    background-repeat: no-repeat;
    background-position: center;
    flex-direction: column;
    height: auto;
    padding: 30px 0;
    margin: 0 auto;
  }
  .video_container video { object-fit: fill;}
  .header_menu > p { font-size: 30px; width: 30%;}
  .header_select_lang select { font-size: 25px;}
  .header_select_lang select option { font-size: 16px;}
  .main_section1_text_box h1 { font-size: 55px;}
  .main_section1_text_box p { font-size: 2.6rem; line-height: 40px;}
  .main_company_section1_wrapper { height: 60vh;}
  .main_company_section2_wrapper {
    background: url(/public/assets/main_company_section1_height_bg1.jpg) no-repeat;
    background-size: 100% 100%;
    height: fit-content;
  }
  .main_company_section3_wrapper {
    /* background: url(/public/assets/main_company_section1_height_bg3.jpg) no-repeat; */
    background-size: 100% 100%;
    height: 60vh;
  }
  .main_company_section4_wrapper {
    /* background: url(/public/assets/main_company_section1_height_bg3.jpg) no-repeat; */
    background-size: 100% 100%;
    height: 60vh;
  }
  .main_company_section2_text_box { font-size: 55px;}
  .main_company_section4_text_box h1 {
    font-size: 55px;
    line-height: 120px;
  }
  .main_company_section3_text_box h1 {
    font-size: 55px;
    line-height: 9rem;
  }
  .onebot_algorithm_section1_wrapper h2 { font-size: 38px;}
  .onebot_algorithm_section1_wrapper { height: 60vh;}
  .onebot_algorithm_section2_wrapper { height: 50vh;}
  .main_section3 { height: 55vh;}
  .main_contact_section { height: 50vh;}
  .main_contact_input_content_text_area { margin: 50px 0;}
  .main_service_introduce_section { height: 50vh;}
  .main_service_introduce_container { height: 25vh;}

  .main_company_names_wrapper {
    background: top center /100% 100%  no-repeat;
    height: 70vh;
  }

}